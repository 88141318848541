<h1>Congés et absences</h1>
<div class="vstack gap-2">
    <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
        <mat-form-field appearance="fill">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="typeFilter" name="requestType" multiple>
                <mat-option *ngFor="let type of StaffActivityTypeEnum.VALUES" [value]="type">
                    {{ type.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Statut</mat-label>
            <mat-select [(ngModel)]="statusFilter" name="requestStatus" multiple>
                <mat-option *ngFor="let status of StaffActivityRequestStatusEnum.VALUES" [value]="status">
                    {{ status.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <a (click)="create()" class="ms-auto">
            <div class="btn btn-important">
                <mat-icon>add</mat-icon>
                Créer une demande
            </div>
        </a>
    </div>

    <div *ngIf="requests()?.length; else elseBlock">
        <div class="card-container d-flex align-items-stretch">
            <a class="card repit-card" *ngFor="let request of pagedRequests()"
               [routerLink]="['/my-work/request/detail/' + request.id]">
                <div class="card-header d-flex gap-2">
                    <div>
                        <div class="card-title">{{ request.type.label }}</div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="card-text">
                        <div>{{ request.status.label }}</div>
                        <div>{{ request.startDate | date:"dd/MM/YYYY" }}- {{ request.endDate | date:"dd/MM/YYYY" }}
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <mat-paginator [length]="this.requests().length"
                       [pageSize]="pageSize()"
                       [pageIndex]="pageIndex()"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       aria-label="Select page"
                       (page)="refreshPagination($event)"
        >
        </mat-paginator>
    </div>
    <ng-template #elseBlock>Aucune demande ne correspond aux critères</ng-template>
</div>