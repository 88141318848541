import {Component, inject, OnInit, signal} from '@angular/core';
import {Store} from "@ngxs/store";
import {ActivatedRoute} from "@angular/router";
import {toSignal} from "@angular/core/rxjs-interop";
import {StaffActivityRequest} from "../../../models/staff/activity/StaffActivityRequest";
import {StaffState} from "../../../stores/staff/staff.state";
import {FindActivityRequest} from "../../../stores/staff/staff.actions";

@Component({
  selector: 'app-detail-staff-activity-request',
  standalone: true,
  imports: [],
  templateUrl: './detail-staff-activity-request.component.html',
  styleUrl: './detail-staff-activity-request.component.scss'
})
export class DetailStaffActivityRequestComponent implements OnInit {
  store = inject(Store);

  id = signal(Number(inject(ActivatedRoute).snapshot.paramMap.get('id')));
  request = toSignal<StaffActivityRequest>(this.store.select(StaffState.selectedActivityRequest));

  ngOnInit(): void {
    this.store.dispatch(new FindActivityRequest(this.id()))
  }

}
