import {StaffMemberSummary} from "../../models/staff/StaffMemberSummary.model";
import {StaffMemberDetail} from "../../models/staff/StaffMemberDetail";
import {AddressWithHistory} from "../../models/address/AddressWithHistory";
import {TimeOutCompRequest} from "../../models/staff/activity/TimeOutCompRequest";
import {TimeOffRequest} from "../../models/staff/activity/TimeOffRequest";
import {StaffActivityRequestFilter} from "./staff.state";

export class RetrieveStaffMembers {
    public static readonly type = '[Staff list] List staff members';
}

export class StaffMembersRetrieved {
    public static readonly type = '[Staff API] Staff members retrieved';

    constructor(public staffMembers: StaffMemberSummary[]) {
    }
}

export class RetrieveActiveEducators {
    public static readonly type = '[Staff list] List active educators';
}

export class ActiveEducatorsRetrieved {
    public static readonly type = '[Staff API] Active educators retrieved';

    constructor(public educators: StaffMemberSummary[]) {
    }
}

export class RetrieveStaffMember {
    public static readonly type = '[Staff list] Retrieve staff member';

    constructor(public staffMemberId: number) {
    }
}

export class StaffMemberRetrieved {
    public static readonly type = '[Staff API] Staff member retrieved';

    constructor(public staffMember: StaffMemberDetail) {
    }
}

export class UpdateStaffMemberAddress {
    public static readonly type = '[Staff detail] Update staff member address';

    constructor(public staffMemberId: number, public addressToUpdate: AddressWithHistory) {
    }
}

export class CreateTimeOutCompRequest {
    public static readonly type = '[Staff detail] Create time out compensation request';

    constructor(public request: TimeOutCompRequest, public close: boolean = true) {
    }
}

export class CreateTimeOffRequest {
    public static readonly type = '[Staff detail] Create time off request';

    constructor(public request: TimeOffRequest, public close: boolean = true) {
    }
}

export class FindMyStaffActivityRequests {
    public static readonly type = '[Staff list] Find my staff activity requests';

    constructor(public filter: StaffActivityRequestFilter) {
    }
}

export class FindActivityRequest {
    public static readonly type = '[Staff list] Find activity request';

    constructor(public activityRequestId: number) {
    }
}